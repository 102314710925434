.plan-image {
    height: 350px;
    margin-bottom: 25px;
    object-fit: cover;
}

.information-wrapper {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
    gap: 10px;
}


.pay-now {
    min-width: 110px;
    padding: 15px !important;
}


.information > span {
    font-weight: 400;
    color: var(--semi-white);
}

.information > h3 {
    width: 100px;
    font-size: 20px;
    margin-top: 5px;
    text-overflow: ellipsis;
}

.information-wrapper::before {
    content: '';
    position: absolute;
    bottom: -25px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: rgba(255, 255, 255, 0.08);
}

.accrual-type svg {
    width: 14px;
    height: 14px;
    margin-right: 6px;
}

.accrual-type span {
    font-weight: 400;
    color: var(--semi-white);
}

.accrual-type p {
    font-weight: 600;
    font-size: 20px;
    margin-top: 5px;
}


.fire-icon {
    margin-right: 6px;
    display: inline-block !important;
}

.statistic {
    display: grid;
    grid-template-columns: repeat(4, minmax(max-content, auto))
}

.statistic p {
    font-size: 14px;
    font-weight: 400;
    color: var(--semi-white);
    margin-bottom: 5px;
}

.statistic span {
    font-weight: 600;
    font-size: 18px;
}

@media screen and (max-width: 1400px) {
    .statistic {
        grid-template-columns: 1fr 1fr;
        gap: 15px;
    }
}

@media screen and (max-width: 1024px) {
    .information > h3, .accrual-type p  {
        font-size: 16px;
    }
}

@media screen and (max-width: 768px) {
    .information-wrapper {
        gap: 5px;
    }

    .information > span {
        font-size: 14px;
    }

    //.information > h3 {
    //    font-size: 17px;
    //}

    .accrual-type span {
        font-size: 14px;
    }

    //.accrual-type p {
    //    font-size: 17px;
    //}
}

@media screen and (max-width: 580px) {
    .statistic {
        grid-template-columns: 105px 45px max-content max-content;
        justify-content: space-between;
        gap: 6px;
    }

}

@media screen and (max-width: 480px) {
    .statistic {
        grid-template-columns: 1fr 1fr;
    }
}