.job-benefits {
    width: 100%;
    margin-bottom: 150px;
}

.job-benefits h2 {
    font-size: 38px;
    font-weight: 700;
    margin-bottom: 32px;
}

.benefits-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px

}

.benefit {
    padding: 30px;
    backdrop-filter: blur(50px);
    border: 1px solid rgba(255, 255, 255, 0.07);
    border-radius: 8px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.02);
    box-shadow: 0px 108px 131px 0px rgba(0, 0, 0, 0.25);
}

.benefit h4 {
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 12px;
}

.benefit p {
    font-weight: 400;
    font-size: 16px;
    color: var(--semi-white);
    line-height: 22px;
}

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 70px;
    height: 70px;
    border-radius: 21px;
    margin-bottom: 30px;
}

@media screen and (max-width: 1200px) {
    .job-benefits {
        margin-bottom: 100px;
    }

    .benefits-list {
        gap: 20px;
    }

    .benefit {
        padding: 20px;
    }

    .benefit p {
        font-size: 14px;
    }
}

@media screen and (max-width: 1024px) {
    .benefits-list {
        grid-template-columns: 1fr 1fr;
    }
}


@media screen and (max-width: 580px) {
    .benefits-list {
        grid-template-columns: 1fr;
    }
}