.main {
    width: 100%;
    background-image:
            url('../../../assets/SVG/Ellipse.svg'),
            url('../../../assets/SVG/Ellipse.svg');
    background-repeat: no-repeat, no-repeat;
    background-size: 100%, 70%, 100%;
    background-position: center top, center top 200px, top;
    padding-top: 75px;
    margin-bottom: 100px;
    white-space: pre-line;
}

.main-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
}



.title {
    max-width: 900px;
    margin-bottom: 20px;
    text-align: center;
    font-weight: 700;
    font-size: clamp(26px, 6.4vw, 60px);
}

.title span {
    background: linear-gradient(90deg, var(--gradient-A), var(--gradient-B));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.subtitle {
    color: var(--semi-white);
    font-size: 16px;
    font-weight: 400;
    text-align: center;
}

.hero-image{
    position: relative;
    right: 50px;
    z-index: -1;
}

.company-statistic {
    width: 100%;
    position: relative;
    top: -80px;
    display: grid;
    padding: 30px 100px;
    grid-template-columns: repeat(4, 1fr);
    backdrop-filter: blur(100px);
    border: 1px solid rgba(255, 255, 255, 0.12);
    border-radius: 8px;
}

.company-statistic li {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.statistic p {
    font-weight: 700;
    font-size: 44px;
    margin-bottom: 5px;
}

.statistic span {
    font-weight: 400;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
}

@media screen and (max-width: 1200px) {
    .main {
        margin-bottom: 25px;
    }

    .hero-image {
        width: 100%;
        height: 100%;
        right: 25px;
    }
}

@media screen and (max-width: 1024px) {
    .hero-image {
        right: 25px;
        top: -50px;
    }

    .company-statistic {
        padding: 20px 30px;
    }
}

@media screen and (max-width: 768px) {
    .main {
        padding-top: 40px;
    }

    .subtitle {
        font-size: 14px;
    }

    .hero-image {
        right: 0;
        top: -15px;
    }

    .company-statistic {
        grid-template-columns: 1fr 1fr;
        padding: 0;
    }

    .company-statistic li {
        padding: 10px 15px;
        border: 1px solid rgba(255, 255, 255, 0.05);
    }

    .statistic span {
        font-size: 12px;
    }

    .statistic p {
        font-size: 34px;
    }
}

@media screen and (max-width: 480px) {
    .statistic p {
        font-size: 28px;
    }
}