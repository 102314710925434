.map-styles_water {
    fill: transparent;
}

.region {
    //fill: rgba(255, 255, 255, 0.05);
    fill: rgba(144, 62, 185, 0.1);
    stroke: var(--background-color);
}

.active-region {
    //fill: rgba(168, 36, 230, 1);
    fill: url(#MyGradient);
}