
@font-face {
  font-family: "Golos";
  font-weight: 400;
  src:
    url("assets/fonts/GolosText-Regular.woff2") format("woff2"),
}

@font-face {
  font-family: "Golos";
  font-weight: 500;
  src:
          url("assets/fonts/GolosText-Medium.woff2") format("woff2"),
}
@font-face {
  font-family: "Golos";
  font-weight: 600;
  src:
          url("assets/fonts/GolosText-SemiBold.woff2") format("woff2"),
}

@font-face {
  font-family: "Golos";
  font-weight: 700;
  src:
          url("assets/fonts/GolosText-Bold.woff2") format("woff2"),
}

:root {
  --background-color: #05071B;
  --gradient-A: #7957FF;
  --gradient-B: #A824E6;
  --gradient-C: #0d122d;
  --gradient-D: #05081f;
  --semi-white: rgba(255, 255, 255, 0.4);
  --red: rgb(255, 84, 84);
}


::-webkit-scrollbar {
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #d6dee1;
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #a8bbbf;
}


body {
  font-family: Golos, serif;
  font-weight: 400;
  color: white;
  background-color: var(--background-color);
}


* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

ul, ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

.container {
  max-width: 1470px;
  height: 100%;
  margin: 0 auto;
  padding: 0 40px;
}

.button {
  display: block;
  background-image: linear-gradient(90deg, var(--gradient-A), var(--gradient-B));
  border: none;
  color: white;
  padding: 14px 40px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;
  text-align: center;
  transition: 1s ease all;
}

.close-button {
  background: linear-gradient(90deg, rgba(121, 87, 255, 0.2) 0%, rgba(168, 36, 230, 0.2) 100%);
}

.custom-bg {
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.03);
  background-image:
          radial-gradient(circle at -10% -10%, rgba(23,46,164,0.15) 20%, rgba(22,119,255,0) 50%),
          radial-gradient(circle at 80% 120%, rgba(23,46,164,0.2) 0%, rgba(22,119,255,0) 50%);
  box-shadow: 0 29px 58px 0 rgba(0, 0, 0, 0.25);
}

.custom-border:before {
  content: "";
  position: absolute;
  z-index: -1;
  inset: 0;
  padding: 2px;
  border-radius: 8px;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(181, 206, 255, 0) 100%);
  -webkit-mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0 0);
  mask:
          linear-gradient(#fff 0 0) content-box,
          linear-gradient(#fff 0, 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}


.suspense {
  padding: 30px;
  height: 100vh;
  background-color: var(--background-color);
}

.error {
  display: flex;
  align-items: center;
  color: var(--red);
}


.error svg {
  margin-right: 10px;
  width: 20px;
  height: 20px;

}

.error svg path {
  fill: var(--red);
}


.index-layout {
  //padding-top: 200px;
}

// CUSTOM-SLIDER

.slick-slide {
  padding: 3px 20px;
}

.slick-list {
  padding: 5px 0;
}

@media screen and (max-width: 1200px) {
  .slick-slide {
    padding: 3px 10px;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 20px;
  }

  .slick-slide {
    padding: 3px 5px;
  }
}

@media screen and (max-width: 480px) {
  .slick-list {
    padding: 0;
  }

  .button {
    padding: 10px 26px;
    font-size: 12px;
  }
}