.selectWrapper {
    position: relative;
    border-radius: 12px;
    padding-right: 20px;
    width: 100%;
}

.arrow {
    position: absolute;
    right: 6px;
    top: 50%;
    transition: transform 0.2s ease-in-out, margin-top 0.2s ease-in-out;
    margin-top: -12px;
}

.placeholder {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    padding: 5px;
    cursor: pointer;
    border: 1px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: white;
    background: transparent;
    box-sizing: border-box;
    width: 100%;
    border-radius: 2px;
}

.placeholder[data-status="default"] {
    border: 1px solid rgba(0, 0, 0, 0.6);
}

.placeholder[data-status="invalid"] {
    border: 1px solid rgba(255, 0, 0, 1);
}

.placeholder[data-selected="true"] {
    color: white;
}

.selectWrapper[data-is-active="true"] .arrow {
    transform: rotate(180deg);
    margin-top: -6px;
}


.selectWrapper:not([data-is-active="true"])
.placeholder:not([data-status="invalid"]):hover {
    border: 1px solid rgba(0, 0, 0, 0.2);
}

.select {
    display: grid;
    position: absolute;
    list-style: none;
    width: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    z-index: 10;
    background-color: rgba(255, 255, 255, 1);
}

.option {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 30px;
    padding: 5px;
    border: 1px;
    cursor: pointer;
    //font-family: "SF Pro";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    background-color: rgba(255, 255, 255, 1);
    color: rgb(0, 0, 0);
}

.option[aria-selected="true"] {
    background-color: rgba(0, 0, 0, 0.05);
}
.option:hover {
    background-color: rgba(0, 0, 0, 0.1);
}

.selectWrapper[data-mode="cells"] .select {
    grid-template-columns: repeat(3, 1fr);
}

.selectWrapper[data-mode="cells"] .option {
    justify-content: center;
}
