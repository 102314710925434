.user-info {
    display: inline-flex;
    position: relative;
    justify-content: space-between;
    padding: 7px 12px;
    min-width: 220px;
    align-items: center;
    background-image: linear-gradient(180deg, var(--gradient-C), var(--gradient-D));
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.12);
}

.avatar {
    cursor: pointer;
    margin-right: 20px;
    border-radius: 50%;
}

.username {
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 500;
    margin-right: 12px;
}


.referral-status-icon {
    //width: 25px;
    margin-top: 3px;
    cursor: pointer;
}

.referral-status-icon polygon {
    fill: rgba(255, 255, 255, 0.5);
}

.exit-icon {
    cursor: pointer;
}