.menu-list {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    min-height: 100vh;
    height: 100%;
    //border: 1px solid rgba(255, 255, 255, 0.05);
    background: linear-gradient(rgba(112, 167, 255, 0.07), rgba(147, 181, 236, 0));
    box-shadow: 0px 4px 4px 0px rgba(25, 30, 40, 1);
    grid-area: menu;
}

.active {
    background: linear-gradient(90deg, var(--gradient-A), var(--gradient-B));
    color: white !important;
    transition: all 0.4s;
}

.active path, .active circle, .active ellipse {
    fill-opacity: 1;
    transition: 0.4s all;
}

.menu-list li {
    display: flex;
    cursor: pointer;
}

.link a {
    display: flex;
    padding: 18px;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    color: rgba(255, 255, 255, 0.5);
}

.menu-list li:not(:last-child) {
    margin-bottom: 5px;
}

.social-links {
    max-width: 150px;
    display: flex;
    margin: auto auto 0 auto;
    justify-content: space-between;
}

.social-links li:not(:last-child) {
    margin-right: 25px;
}

.social-links svg {
    width: 24px;
    height: 24px;
}

.social-links path {
    fill-opacity: 0.4;
}