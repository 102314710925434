.footer {
    position: relative;
    background-color: rgba(5, 7, 27, 1);
    border-top: 1px solid rgba(255, 255, 255, 0.15);
    //border-bottom: 1px solid rgba(255, 255, 255, 0.15);
}

.footer:before {
    content: '';
    position: absolute;
    top: 102px;
    left: 0;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.15);
}

.logotype {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 140px;
    height: 66px;
    z-index: 11;
}

.logotype svg {
    width: 100%;
    height: 100%;
    fill-opacity: 0.4;
}


.top-row {
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.social-links {
    display: flex;
}

.social-links li:not(:last-child) {
    margin-right: 10px;
}

.social-links a {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
}

.social-links svg {
    width: 24px;
    height: 24px;
}

.social-links svg path {
    fill-opacity: 0.4;
}

.bottom-row {
    padding: 40px 0;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
}

.bottom-row p {
    font-size: 12px;
    color: var(--semi-white);
    text-align: center;
}

.links {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: var(--semi-white);
}

@media screen and (max-width: 900px) {
    .footer:before {
        top: 215px;
        //bottom: 65px;
    }

    .top-row {
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
    }
    .bottom-row {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .links {
        text-align: center;
        margin-bottom: 35px;
    }

    .logotype {
        margin: 0 auto;
    }

    .social-links {
        margin: 0 auto;
    }
}

@media screen and (max-width: 580px) {
    .footer:after {
        content: '';
        position: absolute;
        top: 102px;
        left: 0;
        width: 100%;
        height: 1px;
        background: rgba(255, 255, 255, 0.15);
    }

    .footer:before {
        top: 295px;
    }
}

//@media screen and (max-width: 484px) {
//    .footer:before {
//        bottom: 80px;
//    }
//}