.roadmap {
    text-align: center;
    width: 100%;
    padding: 0 40px;
    margin-bottom: 400px;
}

.roadmap h2 {
    margin-bottom: 15px;
}

.subtitle {
    color: var(--semi-white);
    margin-bottom: 100px;
}

.roadmap-wrapper {
    margin: 0 auto;
    position: relative;
}

.roadmap-wrapper img {
    display: block;
}

.vertical-roadmap {
    margin: 0 auto;
    height: auto;
    max-height: 1700px;
    width: auto;
}



.step {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20%;
    height: 350px;
}

.step span {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    margin-bottom: 25px;
    background-image: linear-gradient(90deg, var(--gradient-A), var(--gradient-B));
    font-size: 20px;
    font-weight: 600;
}

.step h3 {
    margin-bottom: 10px;
}

.step p {
    color: var(--semi-white);
    font-size: clamp(14px, 1.1vw, 18px);
    line-height: 1.8;
}

.step:nth-child(2) {
    position: absolute;
    top: -12px;
    left: 3%;
}
.step:nth-child(3) {
    position: absolute;
    top: -12px;
    left: 25%;
}
.step:nth-child(4) {
    position: absolute;
    top: -12px;
    left: 47%;
}
.step:nth-child(5) {
    position: absolute;
    top: -12px;
    left: 69%;
}
.step:nth-child(6) {
    position: absolute;
    bottom: -20.1rem;
    left: 80%;
}
.step:nth-child(7) {
    position: absolute;
    bottom: -20.1rem;
    left: 58%;
}
.step:nth-child(8) {
    position: absolute;
    bottom: -20.1rem;
    left: 36%;
}
.step:nth-child(9) {
    position: absolute;
    bottom: -20.1rem;
    left: 14%;
}


@media screen and (max-width: 1300px) {
    .step span {
        margin-bottom: 15px;
    }

    .step h3 {
        margin-bottom: 5px;
    }

    .step p {
        font-size: 14px;
        line-height: 1.4;
    }
}


@media screen and (max-width: 1024px) {
    .roadmap h2 {
        font-size: 38px;
    }

    .step {
        max-width: 410px;
        width: 100%;
        height: auto;
    }

    .step span {
        margin-bottom: 10px;
    }

    .step h3 {
        margin-bottom: 10px;
    }

    .step p {
        font-size: clamp(14px, 3.2vw, 18px);
        line-height: 1.7;

    }

    .step:nth-child(2), .step:nth-child(3), .step:nth-child(4), .step:nth-child(5),
    .step:nth-child(6), .step:nth-child(7), .step:nth-child(8), .step:nth-child(9) {
        left: 50%;
        transform: translateX(-50%);
    }

    .step:nth-child(2) {
        top: -14px;
    }
    .step:nth-child(3) {
        top: 13%;
    }
    .step:nth-child(4) {
        top: 26.5%;
    }
    .step:nth-child(5) {
        top: 40%;
    }
    .step:nth-child(6) {
        top: 54.8%
    }
    .step:nth-child(7) {
        top: 69.8%
    }
    .step:nth-child(8) {
        top: 84.5%
    }
    .step:nth-child(9) {
        top: 98.1%;
    }
}

@media screen and (max-width: 768px) {
    .roadmap {
        padding: 0 20px;
    }

    .roadmap-wrapper {
        max-width: 520px;
    }
}

@media screen and (max-width: 480px) {
    .roadmap {
        margin-bottom: 250px;
    }

    .subtitle {
        font-size: 14px;
        margin-bottom: 60px;
    }

    .step {
        width: 90%;
        height: 13%;
    }

    .step span {
        min-height: 35px;
        width: 130px;
        margin-bottom: 5px;
        border-radius: 10px;
        font-size: 16px;
    }

    .step-info {
        position: relative;
        display: flex;
        margin-top: auto;
        flex-grow: 1;
        align-items: center;
    }

    .step-info h3 {
        max-width: 51px;
        position: absolute;
        top: 0;
        left: 0;
        margin-bottom: 5px;
        font-size: 16px;
    }

    .step-info p {
        line-height: 1.5;
    }

    .step:nth-child(3) {
        width: 80%;
        top: 12.8%;
    }
    .step:nth-child(4) {
        width: 80%;
        top: 26.5%;
    }
    .step:nth-child(5) {
        width: 80%;
        top: 39.9%;
    }
    .step:nth-child(6) {
        width: 80%;
        top: 54.6%
    }
    .step:nth-child(7) {
        width: 80%;
        top: 69.6%
    }
    .step:nth-child(8) {
        width: 80%;
        top: 84.3%
    }
    .step:nth-child(9) {
        top: 98.1%;
    }
}

@media screen and (max-width: 420px) {
    .step:nth-child(3) {
        top: 12.5%;
    }
    .step:nth-child(4) {
        top: 26%;
    }
    .step:nth-child(5) {
        top: 39.5%;
    }
    .step:nth-child(6) {
        top: 54.2%
    }
    .step:nth-child(7) {
        top: 69.2%
    }
    .step:nth-child(8) {
        top: 83.9%
    }
    .step:nth-child(9) {
        top: 97.6%;
    }
}