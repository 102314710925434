.plans-wrapper {
    position: relative;
}

.tariff-plans {
    width: 100%;
    background-image: url('../../../assets/SVG/Ellipse.svg'), url('../../../assets/SVG/Ellipse.svg');
    background-repeat: no-repeat, no-repeat;
    background-size: 102%, 73%;
    background-position: center top, center top 150px;
    margin-bottom: 350px;
}

.tariff-plans .container > h2 {
    margin-bottom: 20px;
}

.subtitle {
    max-width: 400px;
    font-weight: 400;
    font-size: 16px;
    color: var(--semi-white);
    text-align: center;
    margin: 0 auto 50px;
}

@media screen and (max-width: 1024px) {
    .tariff-plans {
        margin-bottom: 400px;
    }
}


@media screen and (max-width: 768px) {
    .tariff-plans {
        margin-bottom: 50vw;
    }
}