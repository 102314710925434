.contact-form-wrapper {
    width: 100%;
    padding: 100px 20px;
    text-align: center;
    background-image:
            url('../../../assets/SVG/Ellipse.svg'),
            url('../../../assets/SVG/Ellipse.svg'),
            url('../../../assets/images/callback icon.webp'),
            url('../../../assets/images/callback icon.webp'),
            url('../../../assets/images/callback icon.webp');
    background-position:  center 0px, center 250px, 5% 100px, top right 5%, bottom -10% left 30%;
    background-size: 100%, 70%, 150px, 175px, 200px;
    background-repeat: no-repeat;
}

.contact-form-wrapper > p {
    color: var(--semi-white);
    margin-bottom: 100px;
}

.contact-form {
    max-width: 450px;
    margin: 0 auto 50px;
}

.contact-form div:not(:last-child) {
    margin-bottom: 20px;
}

.contact-form-wrapper button {
    background-image: linear-gradient(90deg, var(--gradient-A), var(--gradient-B), #38f948);
    background-size: 400% 100%;
    background-position: 0 100%;
}

.contact-form-wrapper button:disabled {
    background: #e9e9e9;
    color: #858585;
    cursor: not-allowed;
}


.success-button {
    background-position: 100% 100% !important;
}

@media screen and (max-width: 1024px) {
    .contact-form-wrapper {
        background-image:
                url('../../../assets/SVG/Ellipse.svg'),
                url('../../../assets/SVG/Ellipse.svg'),
                url('../../../assets/images/callback icon.webp'),
                url('../../../assets/images/callback icon.webp'),
                url('../../../assets/images/callback icon.webp');
        background-position:  center 0px, center 250px, 5% 100px, top right 5%, bottom -10% left 30%;
        background-size: 100%, 70%, 100px, 125px, 150px;
        background-repeat: no-repeat;
        padding: 0 20px 50px 20px;
    }
}

@media screen and (max-width: 768px) {
    .contact-form-wrapper {
        background-image:
                url('../../../assets/SVG/Ellipse.svg'),
                url('../../../assets/SVG/Ellipse.svg'),
                url('../../../assets/images/callback icon.webp'),
                url('../../../assets/images/callback icon.webp');
        background-position:  center 0px, center 250px, top right 5%, bottom -10% left 30%;
        background-size: 100%, 70%, 125px, 150px;
        background-repeat: no-repeat;
    }
}

@media screen and (max-width: 580px) {
    .contact-form-wrapper > h2 {
        font-size: 32px;
    }

    .contact-form-wrapper > p {
        font-size: 14px;
    }
}