.section-layout {
    display: flex;
    align-items: center;
    justify-content: space-between;
    //flex-direction: column;
    width: 100%;
    gap: 50px;
}

.right {
    flex-direction: row-reverse;
}

.information {
    max-width: 50%;
}

.information p {
    color: var(--semi-white);
    line-height: 32px;
    margin-bottom: 0;
}

.information a {
    margin-top: 50px;
}

@media screen and (max-width: 1200px) {
    .section-layout {
        gap: 70px;
    }

    .image {
        width: 45%;
    }

    .information {
        max-width: initial;
        width: 100%;
    }
}

@media screen and (max-width: 1024px) {
    .section-layout {
        display: block;
    }

    .image {
        width: 50%;
        padding-left: 0;
        padding-right: 30px;
        padding-bottom: 10px;
        float: left;
    }

    .right > img {
        float: right;
        padding-left: 30px;
        padding-right: 0;
        padding-bottom: 10px;
    }
}

@media screen and (max-width: 768px) {
    .information {
        gap: 15px;
    }

    .information p {
        font-size: 16px;
        line-height: 32px;
    }

    .information a {
        margin-top: 25px;
    }

    .right > img {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}

@media screen and (max-width: 568px) {
    .image {
        padding-right: 15px;
    }

    .right > img {
        padding-left: 15px;
    }
}


@media screen and (max-width: 375px) {
    .information p {
        font-size: 14px;
        line-height: 28px;
    }
}

