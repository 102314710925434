.title {
    font-size: 42px;
    font-weight: 700;
}

.left {
    text-align: left;
}

.center {
    text-align: center;
}

@media screen and (max-width: 1024px) {
    .title {
        font-size: 34px;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 30px;
    }
}

@media screen and (max-width: 480px) {
    .title {
        text-align: center;
    }
}

