.privacy-policy {
    padding: 100px 0;
}


.contacts {
    max-width: 300px;
}

.privacy-policy {
    h2 {
        font-size: 20px;
        margin-bottom: 10px;
    }

    div {
        margin-bottom: 40px;
    }
}


.header {
    margin-bottom: 150px !important;

    h1 {
        font-size: clamp(30px, 5vw, 70px);
        font-weight: 500;
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px !important;
        color: rgba(255, 255, 255, 0.35);
    }
}