.nav-header {
    margin: 0 auto;
}

.nav-list {
    display: flex;
    font-size: 15px;
}

.nav-list li {
    color: var(--semi-white);
    transition: 0.5s all;
}

.nav-list-header li:not(:last-child) {
    margin-right: 30px;
}


.nav-list-footer li:not(:last-child) {
    margin-right: 50px;
}

.active {
    color: white;
    transition: 0.5s all;
}


@media screen and (max-width: 1024px) {
    .nav-list-footer li:not(:last-child) {
        margin-right: 15px;
    }
}

@media screen and (max-width: 900px) {
    .nav-footer {
        margin: 20px auto 0;
    }

    .nav-list-footer li:not(:last-child) {
        margin-right: 30px;
    }
}

@media screen and (max-width: 580px) {
    .nav-list-footer {
        display: grid;
        grid-template-columns: max-content max-content;
        grid-template-rows: 1fr 1fr 1fr;
        gap: 20px;
    }

    .nav-list-footer li:not(:last-child) {
        margin-right: 0;
    }
}