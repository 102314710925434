.account-blocked {
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 0, 0, 0.44);
    width: 100vw;
    height: 100vh;
    z-index: 99;
    display: flex;
    justify-content: center;
    align-items: center;
}