.radio-button {
    width: 100%;
    height: 100%;
    cursor: pointer;
}

.radio-button input {
    opacity: 0;
    position: absolute;
}
