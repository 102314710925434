.header {
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    padding: 8px 24px;
    grid-area: header;
}

.header:before {
    content: '';
    width: 1px;
    height: 60%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 260px;
    background-color: rgba(255, 255, 255, 0.2);
}

.logotype {
    position: relative;
    margin-right: 95px;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin: 0 auto 160px
    z-index: 11;
    width: 170px;
    height: 66px;
}

.logotype svg {
    width: 100%;
    height: 100%;
}

.settings {
    display: flex;
    align-items: center;
    margin-left: auto;
}

@media screen and (max-width: 1024px) {
    .header {
        padding: 20px 30px;
    }

    .header:before {
        content: none;
    }

    .logotype {
        height: 45px;
        margin-right: 0;
    }

    .logotype svg {
        height: 45px;
    }
}

@media screen and (max-width: 480px) {
    .logotype {
        width: 140px;
    }
}