.profile-layout {
    display: grid;
    grid-template-areas:
        'header header'
        'menu content';
    grid-template-columns: 260px minmax(0, 1fr);
    padding-left: 9px;
    background-image: radial-gradient(circle at center, rgba(23,46,164,0.22) 0%, rgba(22,119,255,0) 70%);
}

@media screen and (max-width: 1024px) {
    .profile-layout {
        grid-template-areas:
        'header header'
        'content content';
        grid-template-columns: 1fr;
        padding-left: 0;
    }
}