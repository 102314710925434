.header {
    display: flex;
    align-items: center;
    padding: 25px 0;

}

.logotype {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 175px;
    height: 40px;
    //height: 45px;
    //z-index: 11;
}

.logotype svg {
    height: 100%;
}

.buttons {
    display: flex;
    gap: 15px;
    order: 2;
}

.buttons button {
    display: inline-block;
}

.settings {
    display: flex;
    align-items: center;
    width: 100%;
    margin-left: auto;
}


@media screen and (max-width: 1200px) {
    .settings {
        width: auto;
    }
}

@media screen and (max-width: 1024px) {
    .header {
        padding: 20px 0px;
    }

    .settings {
        margin-left: auto;
    }
}

@media screen and (max-width: 768px) {
    .logotype {
        width: 150px;
    }

    .buttons {
        display: none;
    }
}

@media screen and (max-width: 580px) {
    //.logotype {
    //    position: absolute;
    //    top: 0;
    //}
}