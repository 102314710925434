.language {
    margin-right: 15px;
    display: flex;
    width: 120px;
}

.language img {
    margin-right: 10px;
}

.test {
    width: 100px;
    background-color: white;
}

@media screen and (max-width: 1024px) {
    .language {
        width: 90px;
    }
}

@media screen and (max-width: 768px) {
    .language {
        margin-right: 0;
    }
}

