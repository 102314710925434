.menu-btn {
    width: 27px;
    //height: 45px;
    position: relative;
    z-index:11;
    //display: none;
    order: 3;
    background: linear-gradient(-90deg, var(--gradient-A), var(--gradient-B));
    padding: 18px 35px;
    margin-left: 10px;
    border-radius: 8px;
    //overflow: hidden;
}

.menu-open {
    background: linear-gradient(90deg, rgba(121, 87, 255, 0.2) 0%, rgba(168, 36, 230, 0.2) 100%);
}

.menu-btn span {
    width: 27px;
    height: 2px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: rgba(255, 255, 255, 0.8);
    transition: all 0.5s;
    border-radius: 20px;
    opacity: 1;
}
.menu-btn span:nth-of-type(2) {
    top: calc(50% - 6px);
}
.menu-btn span:nth-of-type(3) {
    top: calc(50% + 6px);
    width: 17px;
    left: auto;
    right: 18px;
}

.menu-open span:nth-of-type(1) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotateZ(-45deg);
    width: 17px;
}

.menu-open span:nth-of-type(2) {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) rotateZ(45deg);
    width: 17px
}

.menu-open span:nth-of-type(3) {
    opacity: 0;
}



@media screen and (max-width: 480px) {
    .menu-btn {
        padding: 16px 23px;
    }

    .menu-btn span {
        width: 20px;
    }


    .menu-btn span:nth-of-type(3) {
        right: 6px;
    }
}