.plan-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    border: 1px solid rgba(255, 255, 255, 0.1);
    border-radius: 16px;
    background-color: rgba(255, 255, 255, 0.03);
    box-shadow: 0px 3px 56px 0px rgba(42, 6, 184, 0.11);
    transition: 0.35s ease all;
    outline: 2px solid transparent;
}

.isChecked {
    background: rgba(255, 255, 255,0.12);
    box-shadow: 0px 3px 56px 0px rgba(42, 6, 184, 0.5) !important;
    outline: 2px solid rgb(121, 87, 255);
}


.slick-arrow-prev, .slick-arrow-next {
    position: absolute;
    top: 50%;
    padding: 8px;
    border-radius: 8px;
    border: 1px solid rgba(255, 255, 255, 0.08);
    box-shadow: 0px 3px 15px 0px rgba(42, 6, 184, 0.25);
    background: #171831;
    z-index: 2;
    cursor: pointer;
}

.isActiveDeposit {
    background: rgba(255, 255, 255, 0.4);
    cursor: not-allowed;
}

.isActiveDeposit svg {
    cursor: not-allowed !important;
}

.slick-arrow-prev {
    transform: translateY(-50%) rotate(90deg);
    left: -10px;
}

.slick-arrow-next {
    transform: translateY(-50%) rotate(-90deg);
    right: -10px;
}

@media screen and (max-width: 768px) {
    .plan-wrapper {
        padding: 15px 12px;
    }
}

@media screen and (max-width: 580px) {
    .plan-wrapper {
        padding: 20px;
    }
}