.bg-cover {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    background: rgba(14, 14, 38, 0.7);
    z-index: 10;
    transition: 0.45s ease all 0.15s;
    visibility: hidden;
    opacity: 0;
}

.menu-wrapper {
    background-color: #0e0e26 !important;
    border-radius: 0 !important;
    border-bottom-left-radius: 8px !important;
    border-bottom-right-radius: 8px !important;
    height: 0;
    transition: 0.45s ease all;
    overflow: hidden;
}

.logotype {
    display: flex;
    align-items: center;
    width: 175px;
    height: 40px;
}

.logotype svg {
    height: 100%;
}

.menu-open {
    height: 620px;
}

.menu-open-cover {
    opacity: 1;
    visibility: visible;
}

.mobile-menu {
    margin: 0 auto;
}

.top-row {
    width: 100%;
    height: 95px;
    margin: 0 auto 40px;
    padding: 0 160px 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.4);
}

.social-links {
    display: flex;
    justify-content: center;
    margin-right: 30px;
}

.social-links li:not(:last-child) {
    margin-right: 50px;
    position: relative;
}

.social-links li:not(:last-child):before {
    content: '/';
    position: absolute;
    top: 38%;
    transform: translateY(-50%);
    right: -30px;
}

.lang {
    display: flex;
    gap: 20px;
}

.lang button {
    padding: 10px;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.1);
    border: none;
    color: rgba(255, 255, 255, 0.4);
}

.langIsActive {
    background: linear-gradient(45deg, var(--gradient-A), var(--gradient-B)) !important;
    color: white !important;
}

.nav-links {
    max-width: 80%;
    margin: 0 auto 120px;
    padding-top: 50px;
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-template-areas:
            "home partner ."
            "about-us contacts sign-in"
            "faq . sign-up";
    justify-content: space-between;
    row-gap: 35px;
    column-gap: 20px;
    font-size: 28px;
}

.nav-links li {
    position: relative;
    transition: 0.35s ease all;
}

.nav-links li:before {
    content: '';
    position: absolute;
    top: 50%;
    left: -30px;
    width: 15px;
    height: 2px;
    background: linear-gradient(45deg, var(--gradient-A), var(--gradient-B));
}

.nav-links li:hover {
    color: var(--gradient-B);
}

.nav-links li:nth-child(1) {
    grid-area: home;
}
.nav-links li:nth-child(2) {
    grid-area: partner;
}
.nav-links li:nth-child(3) {
    grid-area: about-us;
}
.nav-links li:nth-child(4) {
    grid-area: contacts;
}
.nav-links li:nth-child(5) {
    grid-area: faq;
}
.nav-links li:nth-child(6) {
    grid-area: sign-in;
}
.nav-links li:nth-child(7) {
    grid-area: sign-up;
}



.registration, .sign-in, .cabinet {
    font-family: inherit;
    font-size: 16px;
    margin-top: auto;
    color: rgba(255, 255, 255, 0.5);
}

.contacts {
    max-width: 80%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    color: rgba(255, 255, 255, 1);
}

.contacts a {
    font-size: 14px;
    font-weight: 500;
}

.contacts a:not(:last-child) {
    margin-right: 20px;
}

.contacts a span {
    color: rgba(255, 255, 255, 0.4);
    font-size: 14px;
    font-weight: 400;
    display: block;
}

@media screen and (max-width: 1024px) {
    .top-row {
        flex-direction: column;
        height: 85px;
        justify-content: center;
        padding-left: 215px;
    }
    
    .logotype {
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
    }


    .social-links {
        position: relative;
        top: 30px;
        margin-right: 0;
        margin-bottom: 25px;
    }

    .lang {
        position: relative;
        top: 30px;
    }

    .contacts {
        flex-direction: column;
        gap: 10px;
    }

    .contacts p:not(:last-child) {
        margin-right: 0;
    }
}

@media screen and (max-width: 768px) {
    .top-row {
        height: 80px;
    }

    .logotype {
        left: 20px;
        width: 150px;
    }

    .menu-open {
        height: 640px;
    }

    .social-links {
        top: 32px;
        font-size: 12px;
    }

    .lang {
        top: 32px;
    }

    .social-links li:not(:last-child) {
        margin-right: 30px;
    }

    .social-links li:not(:last-child):before {
        right: -20px;
    }

    .nav-links {
        font-size: 22px;
        grid-template-columns: max-content max-content;
        grid-template-areas:
            "home partner"
            "about-us contacts"
            "faq ."
            "sign-up sign-in";
        margin-bottom: 50px;
    }
}

@media screen and (max-width: 580px) {
    .mobile-menu {
        padding-bottom: 50px;
    }

    .top-row {
        padding: 0;
        margin: 0 auto 95px;
    }

    .social-links, .lang {
        top: 100px;
    }

    .nav-links {
        font-size: 20px;
    }

    .nav-links li:before {
        width: 12px;
        left: -18px;
    }
}

@media screen and (max-width: 480px) {
    .menu-open {
        overflow: auto;
        height: 100vh;
    }


    .nav-links {
        grid-template-columns: 1fr;
        grid-template-areas:
            "home"
            "partner"
            "about-us"
            "contacts"
            "faq"
            "token"
            "sign-up"
            "sign-in";
        row-gap: 15px;
        max-width: 80%;
    }

    .nav-links li:nth-child(6) {
        margin-bottom: 30px;
    }

    .contacts {
        max-width: 80%;
    }
}