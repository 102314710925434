.partner-program {
    background-image:
            radial-gradient(circle at 400px center, rgba(23,46,164,0.25) 0%, rgba(22,119,255,0) 25%);
    margin-bottom: 200px;
}


@media screen and (max-width: 1024px) {
    .partner-program img {
        width: 45%;
        margin-right: 25px;
        padding-right: 0;
        padding-bottom: 0;
        float: initial;
    }

    .partner-program section > div {
        max-width: 80%;
    }

    .partner-program section {
        flex-direction: column;
    }

    .partner-program  > div {
        display: flex !important;
        gap: 0;
    }
}


@media screen and (max-width: 768px) {
    .partner-program {
        margin-bottom: 130px;
    }

    .partner-program  > div {
        flex-direction: column;
    }

    .partner-program img {
        width: 80%;
        margin-bottom: 60px;
        margin-right: 0;
    }
}

@media screen and (max-width: 580px) {
    .partner-program img {
        width: 100%;
        margin-right: 0;
    }
}