.table-wrapper {
    padding: 0 30px;
}

.table {
    width: 100%;
    text-align: center;
    border-collapse: collapse;
    margin-bottom: 30px;
}

.table th {
    font-weight: 600;
}

.table td {
    padding: 15px;
    border: 1px solid white;
}


.table td button {
    padding: 10px 15px;
}

.table tbody tr td {
    height: 50px;
}

.private-key, .bonus10 {
    margin-bottom: 40px;

    h3 {
        margin-bottom: 10px;
    }

    input {
        font-size: 18px;
        outline: none;
        margin-right: 15px;
        padding: 2px 0;
    }

    select {
        margin-right: 15px;
    }

    button {
        font-size: 14px;
        padding: 0 20px;
        background: var(--gradient-A);
        color: white;
        border: none;
    }
}

.input {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.deposit {
    background: rgba(74, 185, 0, 0.25);
}

.withdrawn {
    background: rgba(245, 40, 40, 0.26);
}

.table td span {
    display: none;
}

@media screen and (max-width: 568px) {
    .table tr th {
        font-size: 12px;
    }

    .table thead tr {
        display: none;
    }

    .table tr {
        display: grid;
        grid-template-columns: 1fr 1fr;
        margin-bottom: 20px;
    }

    .table td {
        padding: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-weight: 600;
        height: 45px !important;
    }

    .table tr td:nth-child(7), .table tr td:last-child {
        padding: 0 !important;
    }

    .table td span {
        display: block;
        opacity: 0.4;
        font-weight: 400;
        font-size: 12px;
    }

    .success-button, .cancel-button {
        padding: 0;
        border: none;
        font-family: inherit;
        background: linear-gradient(90deg, var(--gradient-A), var(--gradient-B));
        color: white;
        font-weight: 600;
        height: 100%;
    }

    .cancel-button {
        background: gray;
        color: rgba(255, 255, 255, 0.5);
    }
}